import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
} from '@coreui/icons'
import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons'
import {
  cil3d,
  cilAddressBook,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilBalanceScale,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilBriefcase,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilMovie,
  cilCart,
  cilCheck,
  cilClock,
  cilCheckCircle,
  cilCode,
  cilCog,
  cilCommentSquare,
  cilCopy,
  cilCreditCard,
  cilContact,
  cilCursor,
  cilCursorMove,
  cilDescription,
  cilDrop,
  cilDollar,
  cilExternalLink,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilGroup,
  cilFile,
  cilFlower,
  cilSync,
  cilApple,
  cilFilter,
  cilFolderOpen,
  cilFullscreen,
  cilFullscreenExit,
  cilHome,
  cilHttps,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoney,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTextSize,
  cilTrash,
  cilTruck,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilWarning,
  cilWallet,
  cilZoom
} from '@coreui/icons'
import { logo } from './logo'


export const iconsSet = Object.assign({}, { logo }, {
  cil3d,
  cilAddressBook,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilBalanceScale,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBookmark,
  cilBriefcase,
  cilCart,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilMovie,
  cilCheck,
  cilClock,
  cilCheckCircle,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCode,
  cilCog,
  cilCommentSquare,
  cilCopy,
  cilCreditCard,
  cilContact,
  cilCursor,
  cilCursorMove,
  cilDescription,
  cilDrop,
  cilDollar,
  cilExternalLink,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilGroup,
  cilFilter,
  cilFile,
  cilFlower,
  cilSync,
  cilApple,
  cilFolderOpen,
  cilFullscreen,
  cilFullscreenExit,
  cilHome,
  cilHttps,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMoney,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPhone,
  cilPrint,
  cilPuzzle,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTextSize,
  cilTrash,
  cilTruck,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  cilXCircle,
  cilWarning,
  cilWallet,
  cilZoom
}, {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
})
