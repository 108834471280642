import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')
const TheAppContainer = () => import('@/containers/TheAppContainer')

//Sections
const Landing = () => import('@/pages/web/Landing')
const Login = () => import('@/pages/application/login/Login')
const NewAccountForm = () => import('@/pages/application/login/NewAccountForm')
const ActivationForm = () => import('@/pages/application/login/ActivationForm')
const ForgetForm = () => import('@/pages/application/login/ForgetForm')
const RecoveryForm = () => import('@/pages/application/login/RecoveryForm')

const FilteredLink = () => import('@/pages/application/FilteredLink')

const Users = () => import('@/pages/application/Users')
const Roles = () => import('@/pages/application/Roles')
const Sizes = () => import('@/pages/application/Sizes')
const Colors = () => import('@/pages/application/Colors')
const Tags = () => import('@/pages/application/Tags')
const Categories = () => import('@/pages/application/Categories')
const Types = () => import('@/pages/application/Types')
const Styles = () => import('@/pages/application/Styles')
const Materials = () => import('@/pages/application/Materials')
const Products = () => import('@/pages/application/Products')
const Sale = () => import('@/pages/application/Sale')
const Sales = () => import('@/pages/application/Sales')
const PreSale = () => import('@/pages/application/PreSale')
const PreSales = () => import('@/pages/application/PreSales')
const Customers = () => import('@/pages/application/Customers')
const Payments = () => import('@/pages/application/Payments')

const ProductPrices = () => import('@/pages/application/ProductPrices')

//Users
const MyAccount = () => import('@/pages/application/configuration/MyAccount')

// Views
const Home = () => import('@/pages/application/Home')
Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      name: 'Inicio',
      redirect: "/sale",
      component: TheContainer,
      children: [
        {
          path: '/home',
          name: 'Inicio',
          component: Home
        },
        {
          path: '/users',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/roles',
          name: 'Roles',
          component: Roles
        },
        {
          path: '/sizes',
          name: 'Tallas',
          component: Sizes
        },
        {
          path: '/colors',
          name: 'Colores',
          component: Colors
        },
        {
          path: '/tags',
          name: 'Etiquetas',
          component: Tags
        },
        {
          path: '/categories',
          name: 'Categorías',
          component: Categories
        },
        {
          path: '/types',
          name: 'Tipos de Producto',
          component: Types
        },
        {
          path: '/styles',
          name: 'Estilos',
          component: Styles
        },
        {
          path: '/materials',
          name: 'Materiales',
          component: Materials
        },
        {
          path: '/products',
          name: 'Productos',
          component: Products
        },
        {
          path: '/sale',
          name: 'Nueva Venta',
          component: Sale
        },
        {
          path: '/sales/:id/view',
          name: 'Ficha de Venta',
          component: Sale
        },
        {
          path: '/sales',
          name: 'Todas las Ventas',
          component: Sales
        },
        {
          path: '/presale',
          name: 'Nuevo Apartado',
          component: PreSale
        },
        {
          path: '/presales/:id/view',
          name: 'Ficha de Apartado',
          component: PreSale
        },
        {
          path: '/presales',
          name: 'Todos los Apartados',
          component: PreSales
        },
        {
          path: '/payments',
          name: 'Pagos Recibidos',
          component: Payments
        },
        {
          path: '/customers',
          name: 'Clientes',
          component: Customers
        },
        {
          path: '/product-prices',
          name: 'Precios de Productos',
          component: ProductPrices
        },
      ]
    },
    {
      path: '/',
      name: 'Bienvenido',
      meta: {
        is_public: true,
      },
      component: Landing
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        is_public: true,
      },
      component: Login
    },
    {
      path: '/signup',
      name: 'Registra tu Cuenta',
      meta: {
        is_public: true,
      },
      component: NewAccountForm
    },
    {
      path: '/account/active/:id/:code',
      name: 'Activa tu Cuenta',
      meta: {
        is_public: true,
      },
      component: ActivationForm
    },
    {
      path: '/forget',
      name: '¿Olvidaste tu Contraseña?',
      meta: {
        is_public: true,
      },
      component: ForgetForm
    },
    {
      path: '/account/recover-password/:id/:code',
      name: 'Recupera tu Contraseña',
      meta: {
        is_public: true,
      },
      component: RecoveryForm
    },
    {
      path: '/:code/filtered-link',
      name: 'Filtro de Productos',
      component: TheAppContainer,
      is_public: true,
      children: [
        {
          path: '/:code/link',
          name: 'Filtro de Productos',
          meta: {
            is_public: true,
          },
          component: FilteredLink
        }
      ]
    }
  ]
})