import currency from './currency';
import decimal from './decimal';
import decimals from './decimals';
import date from './date';
import nl2br from './nl2br';

export default {
    install(Vue, options) {
        Vue.filter('currency', currency);
        Vue.filter('decimal', decimal);
        Vue.filter('decimals', decimals);
        Vue.filter('date', date);
        Vue.filter('nl2br', nl2br);

        Vue.prototype.$parseSelectOptions = async function(items, value = "value", label = "label", defaultValue = []) {
            let newList = [];

            newList.push({label: 0, value: ''});

            let labels = label.split(":");
            let separator = ' - ';

            if(label.indexOf('|') >= 0){
                labels = label.split("|");
                separator = '';
            }

            if(items.length > 0){
                if(labels.length == 1){
                    items.forEach(item => {
                        newList.push({label: item[labels[0]], value: item[value]});
                    });
                }
                else{
                    items.forEach(item => {
                        if(labels.length == 2){
                            newList.push({label: item[labels[0]] + separator + item[labels[1]], value: item[value]});
                        }

                        if(labels.length == 3){
                            newList.push({label: item[labels[0]] + separator + item[labels[1]] + separator + item[labels[2]], value: item[value]});
                        }
                    });
                }
            }

            let newListArray = [...defaultValue, ...newList];

            return newListArray;
        }

        Vue.prototype.$parseSelectOptionsOrdered = async function(items, value = "value", label = "label", defaultValue = []) {
            let newList = await this.$parseSelectOptions(items, value, label);

            let vm = this;
            newList = vm.$sort(newList, 'ASC', 'label');       

            let newListArray = [...defaultValue, ...newList];

            return newListArray;
        }

        Vue.prototype.$discardOptions = async function(items, discards, fields = "label:code") {
            let newList = [];

            fields = fields.split(":");

            items.forEach(item => {
                let flag = false;
                
                discards.forEach(discard => {
                    if(item[fields[0]] === discard[fields[1]]){
                        flag = true;
                    }
                });

                if(!flag){
                    newList.push(item);
                }
            });

            let vm = this;
            newList = vm.$sort(newList, 'ASC', 'label');

            return newList;
        }

        Vue.prototype.$findDefaultTax = function(taxes) {
            let element = taxes[0];
            Object.entries(taxes).forEach(([key,value]) => {
                if(value.name === " IVA 16.00%"){
                    element = value;
                }
            });
            return element;
        }

        Vue.prototype.$findTax = function(taxes, form_tax) {
            let element = taxes[0];
            Object.entries(taxes).forEach(([key,value]) => {
                if(value.code === form_tax){
                    element = value;
                }
            });

            element.id = form_tax;
            return element;
        }

        Vue.prototype.$findDefaultUnit = function(units) {
            let element = units[0];
            Object.entries(units).forEach(([key,value]) => {
                if(value.code === "E48"){
                    element = value;
                }
            });
            return element;
        }

        Vue.prototype.$findIndexByCode = function(obsObject, code) {
            let element;
            Object.entries(obsObject).forEach(([key,value]) => {
                if(value.code+"" === code+""){
                    element = key;
                }
            });
            return key;
        }

        Vue.prototype.$findElementByCode = function(obsObject, code) {
            let element;
            Object.entries(obsObject).forEach(([key,value]) => {
                if(value.code+"" === code+""){
                    element = value;
                }
            });
            return element;
        }

        Vue.prototype.$findElementByValue = function(obsObject, value) {
            let element;
            Object.entries(obsObject).forEach(([key,code]) => {
                if(code.value+"" === value+""){
                    element = code;
                }
            });
            return element;
        }

        Vue.prototype.$findElementByName = function(obsObject, value) {
            let element;
            Object.entries(obsObject).forEach(([key,code]) => {
                if(code.name+"" === value+""){
                    element = code;
                }
            });
            return element;
        }

        Vue.prototype.$findIndexByText = function(obsObject, value) {
            let index;
            Object.entries(obsObject).forEach(([key,code]) => {
                if(code.text+"" === value+""){
                    index = key;
                }
            });
            return index;
        }

        Vue.prototype.$removeElementByCode = function(obsObject, code) {
            
            let element = [];
            Object.entries(obsObject).forEach(([key,value]) => {
                if(value.code+"" !== code+""){
                    element.push(value);
                }
            });
            return element;
        }

        Vue.prototype.$findElementByUUID = function(obsObject, uuid) {
            let element;
            Object.entries(obsObject).forEach(([key,value]) => {
                if(value.uuid+"" === uuid+""){
                    element = value;
                }
            });
            return element;
        }

        Vue.prototype.$findElementById = function(obsObject, id) {
            let element;
            Object.entries(obsObject).forEach(([key,value]) => {
                if(value.id+"" === id+""){
                    element = value;
                }
            });
            return element;
        }

        Vue.prototype.$findSerialByType = function(serials, type = 'income') {
            let element;
            Object.entries(serials).forEach(([key,value]) => {
                if(value.type == type && value.active == 1){
                    element = value;
                }
            });
            return element;
        }

        Vue.prototype.$findSerialByName = function(serials, name) {
            let element;
            Object.entries(serials).forEach(([key,value]) => {
                if(value.serial == name && value.active == 1){
                    element = value;
                }
            });
            return element;
        }

        Vue.prototype.$isNullorUndefined = function(value) {
            let response = (typeof value === 'undefined') || value == null ? true : false;
            return response;
        }

        Vue.prototype.$sort = function(list, sort = 'ASC', property = 'name'){
            let newList = [];
            let vm = this;
            if(!this.$isNullorUndefined(list) && typeof list === 'object'){
                Object.entries(list).forEach(([key, value]) => {
                    if(!this.$isNullorUndefined(value)){
                        newList.push(value);
                    }
                });
            }else{
                if(Array.isArray(list)){   
                    newList = list;
                }
            }
            if(newList.length > 0){
                if(sort === 'ASC'){
                    newList.sort(function(a, b){
                        let nameA = a[property] !== '' && a[property] !== 0 && !vm.$isNullorUndefined(a[property]) ? a[property].toLowerCase(): '';
                        let nameB = b[property] !== '' && b[property] !== 0 && !vm.$isNullorUndefined(b[property]) ? b[property].toLowerCase(): '';
                        if (nameA < nameB) //sort string ascending
                            return -1 
                        if (nameA > nameB)
                            return 1
                        return 0 //default return value (no sorting)
                    });
                }
                if(sort === 'DESC'){
                    newList.sort(function(a, b){
                        let nameA = a[property] !== '' && !vm.$isNullorUndefined(a[property]) ? a[property].toLowerCase(): '';
                        let nameB = b[property] !== '' && !vm.$isNullorUndefined(b[property]) ? b[property].toLowerCase(): '';
                        if (nameA < nameB) //sort string ascending
                            return 1 
                        if (nameA > nameB)
                            return -1
                        return 0 //default return value (no sorting)
                    });
                }
            }
            return newList;
        }
    }
}